import React, { Suspense, useCallback, useMemo, useRef, useState } from "react";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import FormJSS from "./form";
import html2canvas from "html2canvas";
import { Canvas, useThree } from "@react-three/fiber";
import helmetimg from "./helmetimg.png";
import glassimg from "./glass.png";
import eardefenderimg from "./eardefender.png";
import gasmaskimg from "./gasmask.png";
import {
  FaceTracker,
  HeadMaskMesh,
  ZapparCamera,
  Loader,
  Pipeline,
  Types,
} from "@zappar/zappar-react-three-fiber";
import helmetSrc from "./assets/z_helmet.glb";
import helmetSrc1 from "./assets/glass.glb";
import eardefender from "./assets/eardefender.glb";
import gasmask from "./assets/Gas_Mask.glb";
import { stat } from "fs";

import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";

function Helmet() {
  const [gltf, set] = useState<GLTF>();

  useMemo(() => new GLTFLoader().load(helmetSrc, set), []);

  if (gltf) {
    const { scene } = gltf;
    const Helmet = scene.getObjectByName("Helmet");
    return (
      <primitive
        position={[0, 0.7, 0.1]}
        scale={[0.6, 0.7, 1]}
        rotation={[0.1, 0, 0]}
        object={Helmet}
      />
    );
  }
  return null;
}

function Helmet1() {
  const [gltf, set] = useState<GLTF>();
  useMemo(() => new GLTFLoader().load(helmetSrc1, set), []);

  if (gltf) {
    const { scene } = gltf;
    const Helmet = scene.getObjectByName("glass");
    return (
      <primitive
        position={[0.03, 0.3, 0.85]}
        scale={[0.3, 0.3, 0.33]}
        rotation={[0.2, -89.5, 0.2]}
        object={Helmet}
      />
    );
  }
  return null;
}

function EarDefender() {
  const [gltf, set] = useState<GLTF>();
  useMemo(() => new GLTFLoader().load(eardefender, set), []);

  if (gltf) {
    const { scene } = gltf;
    const Helmet = scene.getObjectByName("eardefender");
    return (
      <primitive
        position={[-0.03, 0.5, 0.4]}
        scale={[0.05, 0.055, 0.06]}
        rotation={[90, 0, -80]}
        object={Helmet}
      />
    );
  }
  return null;
}

function GasMask() {
  const [gltf, set] = useState<GLTF>();

  useMemo(() => new GLTFLoader().load(gasmask, set), []);

  if (gltf) {
    const { scene } = gltf;
    const Helmet = scene.getObjectByName("GasMask");
    return (
      <primitive
        position={[0, -0.2, 0.99]}
        scale={[0.2, 0.2, 0.07]}
        rotation={[89.5, 0, 0]}
        object={Helmet}
      />
    );
  }
  return null;
}
function FaceFilter() {
  const faceTrackerGroup = useRef<Types.FaceAnchorGroup>();
  const pipeline = new Pipeline();
  const [state, setState] = useState(4);
  const [state1, setState1] = useState(0);
  const zcanv = useRef<any>();
  const canvasRef = useRef<any>();
  const [imgurl, setImage] = useState("");
  const addImg = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "vodacom"), {
        img: imgurl,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <>
      {state == 0 && (
        <div style={{ width: "100vw", height: "100vh" }}>
          {state1 == 1 && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "0",
                top: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "80%",
                  background: "#00000088",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <div style={{ color: "red" }}>Registered Successful</div>
              </div>
            </div>
          )}
          <img src={imgurl} alt="" />
          {state1 == 0 && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                bottom: "100px",
                gap: "30px",
              }}
            >
              <button
                style={{
                  padding: "5px 50px",
                  background: "#000000bb",
                  color: "#fff",
                }}
                onClick={() => setState(1)}
              >
                Try Again
              </button>
              <button
                style={{
                  padding: "5px 50px",
                  background: "#000000bb",
                  color: "#fff",
                }}
                onClick={(e) => {
                  console.log(imgurl);
                  addImg(e);
                  setState1(1);
                }}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      )}
      {state == 1 && (
        <Canvas ref={canvasRef} gl={{ preserveDrawingBuffer: true }}>
          <ZapparCamera
            pipeline={pipeline}
            userFacing
            userCameraMirrorMode="none"
          />
          <FaceTracker ref={faceTrackerGroup} pipeline={pipeline}>
            <Suspense fallback={null}>
              <HeadMaskMesh trackerGroup={faceTrackerGroup} />
              <Helmet />
            </Suspense>
          </FaceTracker>
          {/* <ambientLight intensity={0.6} /> */}
          <directionalLight position={[2.5, 8, 5]} intensity={1.5} />
          <Loader />
        </Canvas>
      )}
      {state == 2 && (
        <Canvas ref={canvasRef} gl={{ preserveDrawingBuffer: true }}>
          <ZapparCamera
            pipeline={pipeline}
            userFacing
            userCameraMirrorMode="none"
          />
          <FaceTracker ref={faceTrackerGroup} pipeline={pipeline}>
            <Suspense fallback={null}>
              <HeadMaskMesh trackerGroup={faceTrackerGroup} />
              <Helmet1 />
            </Suspense>
          </FaceTracker>
          {/* <ambientLight intensity={0.6} /> */}
          <directionalLight position={[2.5, 8, 5]} intensity={1} />
          <Loader />
        </Canvas>
      )}
      {state == 3 && (
        <Canvas ref={canvasRef} gl={{ preserveDrawingBuffer: true }}>
          <ZapparCamera
            pipeline={pipeline}
            userFacing
            userCameraMirrorMode="none"
          />
          <FaceTracker ref={faceTrackerGroup} pipeline={pipeline}>
            <Suspense fallback={null}>
              <HeadMaskMesh trackerGroup={faceTrackerGroup} />
              <EarDefender />
            </Suspense>
          </FaceTracker>
          {/* <ambientLight intensity={0.6} /> */}
          <directionalLight position={[2.5, 8, 5]} intensity={1.5} />
          <Loader />
        </Canvas>
      )}
      {state == 4 && (
        <Canvas ref={canvasRef} gl={{ preserveDrawingBuffer: true }}>
          <ZapparCamera
            pipeline={pipeline}
            userFacing
            userCameraMirrorMode="none"
          />
          <FaceTracker ref={faceTrackerGroup} pipeline={pipeline}>
            <Suspense fallback={null}>
              <HeadMaskMesh trackerGroup={faceTrackerGroup} />
              <GasMask />
            </Suspense>
          </FaceTracker>
          {/* <ambientLight intensity={0.6} /> */}
          <directionalLight position={[2.5, 8, 5]} intensity={1.5} />
          <Loader />
        </Canvas>
      )}
      {state != 0 && (
        <div
          style={{ position: "absolute", bottom: 10, left: 0, width: "100%" }}
        >
          <nav style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                width: "80px",
                height: "80px",
                background: "gray",
                borderRadius: "50%",
              }}
              onClick={() => {
                // console.log(dataUrl);
                // setImage(dataUrl);
                const link = document.createElement("a");
                const canvas = document.querySelector(
                  "canvas"
                ) as HTMLCanvasElement; // this was the gl dom element
                link.setAttribute("download", `Screenshot-${Date.now()}.png`);
                link.setAttribute(
                  "href",
                  canvas // instead of gl.domElement
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream")
                );
                // link.click();
                // link.remove();
                setImage(
                  canvas // instead of gl.domElement
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream")
                );
                setState(0);
              }}
            ></button>
          </nav>
          <nav
            style={{
              height: "100px",
              overflowX: "auto",
              display: "flex",
              justifyContent: "center",
              gap: "1em",
            }}
          >
            <button
              style={{
                width: "80px",
                height: "80px",
                background: "#ffffff22",
                borderRadius: "50%",
                overflow: "hidden",
              }}
              onClick={() => setState(1)}
            >
              <img src={helmetimg} style={{ width: "100%" }} alt="" />
            </button>
            <button
              style={{
                width: "80px",
                height: "80px",
                background: "#ffffff22",
                borderRadius: "50%",
                overflow: "hidden",
              }}
              onClick={() => setState(2)}
            >
              <img src={glassimg} style={{ width: "100%" }} alt="" />
            </button>
            <button
              style={{
                width: "80px",
                height: "80px",
                background: "#ffffff22",
                borderRadius: "50%",
                overflow: "hidden",
              }}
              onClick={() => setState(3)}
            >
              <img src={eardefenderimg} style={{ width: "80%" }} alt="" />
            </button>
            <button
              style={{
                width: "80px",
                height: "80px",
                background: "#ffffff22",
                borderRadius: "50%",
                overflow: "hidden",
              }}
              onClick={() => setState(4)}
            >
              <img src={gasmaskimg} style={{ width: "70%" }} alt="" />
            </button>
          </nav>
        </div>
      )}
    </>
  );
}

function App() {
  const [state, setState] = useState(0);
  return <>{state == 0 ? <FormJSS setState={setState} /> : <FaceFilter />}</>;
}

export default App;
