import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import header from "./header.png";
export default function BasicTextFields({ setState }) {
  return (
    <div
      style={{
        backgroundImage: "url(bg.jpg)",
        backgroundSize: "100% 100%",
        width: "100%",
        minHeight: "100vh",
        maxWidth: "450px",
        backgroundAttachment: "fixed",
        margin: "auto",
      }}
    >
      <div>
        <img src={header} style={{ width: "100%" }} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "450px",
          margin: "auto",
          width: "100%",
          alignItems: "center",
          gap: "1em",
          paddingTop: "3em",
        }}
      >
        <div
          style={{
            width: "90%",

            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <nav style={{ color: "#fff" }}>Full Name</nav>
          <input
            type="text"
            style={{
              width: "100%",
              padding: "10px",
              background: "#ffffff99",
              boxSizing: "border-box",
              border: "none",
              borderRadius: "1em",
            }}
          />

          <div
            style={{
              width: "90%",

              display: "flex",
              flexDirection: "column",
              gap: "5px",
              margin: "auto",
            }}
          >
            <button
              style={{ padding: "8px", borderRadius: "10px", marginTop: "2em" }}
              onClick={() => {
                setState(1);
              }}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
